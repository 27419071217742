import { gql } from 'apollo-boost'

export const typeDefs = gql`
	extend type Query {
		isLoggedIn: Boolean!
		loggedInUser: User!
		groupWork: [TypeGroupWork]!
	}

	extend type User {
		id: String!
		groupCode: String!
		loginTime: String!
	}

	extend type ResponseGroupWork {
		success: Boolean!
		list: [TypeGroupWork]!
	}

	extend type TypeGroupWork {
		authRead: Int
		authAdd: Int
		authModify: Int
		authDelete: Int
	}
`
