import React, { Component } from 'react'
import { Route, Switch, HashRouter } from 'react-router-dom'
import Loadable from 'react-loadable'

// for graphql
import { ApolloProvider } from 'react-apollo'
import apolloClient from './graphql'

import './App.scss'

const loading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>

// Containers
const DefaultLayout = Loadable({
	loader: () => import('./containers/DefaultLayout'),
	loading,
})

const Login = Loadable({
	loader: () => import('./containers/Login'),
	loading,
})

const Register = Loadable({
	loader: () => import('./views/Pages/Register'),
	loading,
})

const Page404 = Loadable({
	loader: () => import('./views/Pages/Page404'),
	loading,
})

const Page500 = Loadable({
	loader: () => import('./views/Pages/Page500'),
	loading,
})

class App extends Component {
	render() {
		return (
			<ApolloProvider client={apolloClient}>
				<HashRouter>
					<Switch>
						{/* <Route exact path="/login" name="Login Page" component={Login} /> */}
						<Route exact path='/register' component={Register} />
						<Route exact path='/404' component={Page404} />
						<Route exact path='/500' component={Page500} />
						{/* <Route exact path="/dashboard" component={DefaultLayout} /> */}
						<Route exact path='/' component={Login} />
						<Route exact path='/home' component={DefaultLayout} />
						<Route path='/main' component={DefaultLayout} />
						{/* <Route exact path="/login" name="Login Page" component={Login} />
              <Route exact path="/register" name="Register Page" component={Register} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
          */}
					</Switch>
				</HashRouter>
			</ApolloProvider>
		)
	}
}

export default App
