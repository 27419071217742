import { gql } from 'apollo-boost'
import client from './index'

const QUERY_CURRENT_USER = gql`
	query GetCurrentUserId {
		loggedInUser @client {
			id
			groupCode
		}
	}
`

const QUERY_GROUP_WORKS = gql`
	query GetGroupWorks {
		groupWorks @client {
			menuCode
			authRead
			authAdd
			authModify
			authDelete
		}
	}
`

/**
 * @returns [string] Id 현재로그인한 유저 ID
 * @returns [string] groupCode 현재로그인한 유저 그룹코드
 */
export const currentUser = async () => {
	if (!client) return ''

	const {
		error,
		data: {
			loggedInUser: { id, groupCode, p_code, p_name },
		},
	} = await client.query({
		query: QUERY_CURRENT_USER,
	})

	if (error) return ''
	return {
		id,
		groupCode,
		p_code,
		p_name,
	}
}

export const currentMenuAuth = async menuCode => {
	const defaultResult = {
		menuCode: menuCode,
		authRead: 1,
		authAdd: 1,
		authModify: 1,
		authDelete: 1,
	}

	if (!client) return defaultResult

	const {
		error,
		data: { groupWorks },
	} = await client.query({
		query: QUERY_GROUP_WORKS,
	})

	// 에러나면 권한 전체 다 주자.
	if (error) return defaultResult
	const result = groupWorks.filter(val => val.menuCode === menuCode)
	return result.length > 0 ? result[0] : defaultResult
}
