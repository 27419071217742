// import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { typeDefs } from './resolvers'
import { currentUser, currentMenuAuth } from './queries'
import { getMainDefinition } from 'apollo-utilities'

const SERVER_URI = `${process.env.REACT_APP_SERVER_URI}/graphql`
// const WEB_SOCKET_URI = `ws://${process.env.REACT_APP_SERVER_URI}/graphql`
// const SERVER_URI = process.env.NODE_ENV === 'development' ? '/graphql' : 'http://210.116.92.72:30004/graphql'

const cache = new InMemoryCache()

// create an http link:
const httpLink = createHttpLink({ uri: SERVER_URI })

// create a websocket link:
// const wsLink = new WebSocketLink({
// 	uri: WEB_SOCKET_URI,
// 	options: {
// 		reconnect: true,
// 	},
// })

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
	// split based on operation type
	({ query }) => {
		const definition = getMainDefinition(query)
		// return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
		return definition.kind === 'OperationDefinition'
	},
	// wsLink,
	httpLink
)

const GraphQLClient = new ApolloClient({
	cache,
	link,
	// uri: SERVER_URI,
	clientState: {
		typeDefs,
	},
	resolvers: {}, // @client directive에 관한 경고 없애기 위함.
})

GraphQLClient.writeData({
	data: {
		isLoggedIn: false,
		loggedInUser: {
			id: '',
			groupCode: '',
			loginTime: '',
			p_code: null,
			p_name: null,
			__typename: 'loggedInUser',
		},
		groupWorks: [],
	},
})

export default GraphQLClient

export { currentUser, currentMenuAuth }

export const initMenuAuth = menuCode => ({
	menuCode,
	authRead: 1,
	authAdd: 1,
	authModify: 1,
	authDelete: 1,
})
